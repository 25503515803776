import React, { Component } from "react";
import Slider from "react-slick";
import HomeForm from "../Homepage/homeform";
import Mission from "../Homepage/mission";
import Logo from "../../assets/images/logo.png";
import LandScape from "../../assets/images/landscape.jpeg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} tab tabs-tab-right-white`}
      style={{
        ...style,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} tab tabs-tab-left-white`}
      id="home-tab-left"

      style={{
        ...style,
      }}
      onClick={onClick}
    />
  );
}
export default class SimpleSlider extends Component {

  render() {
    const settings = {
      dots: false,
      initialSlide: 1,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "slides",
      // centerMode: true,
      // centerPadding: '180px',

      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      afterChange: (current, next) => {

        if (current === 0) {
          let element = document.querySelector('.slick-active');
          element?.classList.add('nextslideanim');
        } else {
          let element = document.querySelector('.slick-active');
          element?.classList.remove('nextslideanim')
        }

      }

    };
    const myStyle = {
      background: `white url(${LandScape}) 50% 0 no-repeat fixed`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      marginTop: "48px",
      backgroundPosition: "50% 0px",

    };
    const h2 = {
      fontSize: "2.25em",
      lineHeight: ".8em",
      textAlign: "center",
      fontFamily: "AvenirNextBold",
      fontWeight: "normal"
    }
    const h4 = {
      fontSize: "1.125em",
      lineHeight: "1.33333em",
      textAlign: "center"

    }

    return (
      <div>
        <Slider  {...settings}>
          <div id="home-slide-left">
            <Mission />
          </div>
          <div id="home-slide-center">
            <div style={myStyle}>
              <img src={Logo} className="header" />
              <div className="l-page-body l-page-body-headless" style={{ marginTop: "3em" }}>
                <div className="content-blocks">
                  <div className="content-block alt">
                    <h2 style={h2}>
                      Rethinking the World!!!!
                    </h2>
                    <h4 style={h4}>
                      It's your land, your planet, your future!
                    </h4>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="home-slide-right">
            <HomeForm />
          </div>
        </Slider>
      </div >
    );
  }
}
