import React, { useEffect, useState } from "react";
// import {SmallBox,BigBox} from '../../button';
import { Dashboardcss } from "./styles.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/images/logo.png";
import { dashboardService } from "../../services/dashboard.service";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import State from "../../components/State";
import Country from "../../components/Country";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const [userData, setUserdata] = useState({});
  function getData(id) {
    dashboardService.getmemberdetailsById(id).then(
      (res) => {
        if (res.status === "success") {
          setUserdata(res.memberprofile);
        } else {
          toast.error("Something went wrong", "Dashboard");
        }
      },
      (error) => {
        toast.error("Something went wrong", "Dashboard");
      }
    );
  }
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  let minOffset = 0,
    maxOffset = 20;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear + x);
  }

  const yearList = allYears.map((x) => {
    return <option key={x}>{x}</option>;
  });
  const [isOpened, setIsOpened] = useState(false);
  const [isOpened1, setIsOpened1] = useState(false);
  function toggle() {
    setIsOpened(true);
    setIsOpened1(false);
  }
  function toggle1() {
    setIsOpened1(true);
    setIsOpened(false);
  }

  useEffect(() => {
    const usedata = JSON.parse(localStorage.getItem("userDetails"));

    getData(usedata.id);
  }, []);
  return (
    <div className="l-page-body l-page-body-headless mt-4">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Member Dashboard</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <Dashboardcss>
        <img src={Logo} className="header" />
        <h1 className="l-page-title mt-2">
          <span className="l-page-title-text">My Account</span>
        </h1>
        <div className="row">
          <div className="col-md-6">
            <h2 className="account">Account</h2>
            <table
              className="table table-bordered"
              style={{ border: "none", borderColor: "snow" }}
            >
              {/* <table className="table table-bordered"> */}
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{userData.firstName}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{userData.lastName}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{userData.email}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{userData.phone}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{userData.address}</td>
                </tr>
                <tr>
                  <td>Company Name</td>
                  <td>{userData.companyName}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{userData.city}</td>
                </tr>
                <tr>
                  <td>Zip Code</td>
                  <td>{userData.postal_code}</td>
                </tr>
                <tr>
                  <td>Your Own Referral Code</td>
                  <td>
                    {userData.code} &nbsp;
                    <a onClick={showModal}>What's this?</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="btns">
              <Link to="/members/account" className="edit btn">
                Edit
              </Link>
            </p>
            <p className="btns">
              <a href="/member/members/refer" className="edit btn">
                Refer a New Member
              </a>{" "}
            </p>

            <ul className="menu_member-links">
              <li>
                <a
                  style={{ color: "#f7931e" }}
                  href="/terms-and-conditions"
                  title=""
                >
                  Terms &amp; Conditions
                </a>
              </li>
              <li>
                <a
                  style={{ color: "#f7931e" }}
                  href="/land-use-license-agreement"
                  title=""
                >
                  Land Use License Agreement
                </a>
              </li>
            </ul>
          </div>

          <Modal show={isOpen} onHide={hideModal}>
            <Modal.Header>
              <Modal.Title>Hi</Modal.Title>
            </Modal.Header>
            <Modal.Body>The body</Modal.Body>
            <Modal.Footer>
              <button onClick={hideModal}>Cancel</button>
              <button>Save</button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-6">
            <h2 className="account">Payment Information</h2>
            <p className="begin">
              To begin donating to Land Preserve, please set up your billing and
              payment information <a href="/members/payment">here</a>{" "}
            </p>
          </div>
        </div>

        <h1 className="l-page-title mt-2">
          <span className="l-page-title-text">REFER A NEW MEMBER</span>
        </h1>
        <div className="row">
          <div className="col-md-6">
            <h2 className="account_information mt-3">ACCOUNT INFORMATION</h2>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Enter email*"
                name="email"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password*"
                name="password"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password_confirmation"
                placeholder="Confirm Password*"
                name="password_confirmation"
              />
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="term"
                className="form-check-input"
                id="exampleCheck1"
                value="1"
              />
              <label
                className="form-check-label"
                htmlFor="exampleCheck1"
                style={{ color: "#000" }}
              >
                I agree to the{" "}
                <Link target={"_blank"} to="/terms-and-conditions">
                  {" "}
                  Terms and Conditions
                </Link>
              </label>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="license"
                className="form-check-input"
                id="exampleCheck2"
                value="1"
              />
              <label
                className="form-check-label"
                htmlFor="exampleCheck2"
                style={{ color: "#000" }}
              >
                I agree to the{" "}
                <Link target={"_blank"} to="/land-use-license-agreement">
                  {" "}
                  Land Use License Agreement
                </Link>{" "}
              </label>
            </div>
            <h2 className="account_information mt-5">BILLING INFORMATION</h2>

            <form>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName"
                  placeholder="Enter name*"
                  name="name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  placeholder="Last name*"
                  name="last_name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="company_name"
                  placeholder="Company Name*"
                  name="company_name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Address *"
                  name="address"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="address_2"
                  placeholder="Address 2"
                  name="address_2"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City*"
                  name="city"
                />
              </div>
              <div className="form-group">
                <State />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="postal_code"
                  placeholder="Postal Code *"
                  name="postal_code"
                />
              </div>
              <div className="form-group">
                <Country />
              </div>
              <div className="form-group"></div>
              {/* <input type="submit" defaultValue="Save" /> */}
            </form>
          </div>
          <div className="col-md-1"></div>

          <div className="col-md-5">
            <h2 className="payment_information mt-3">PAYMENT INFORMATION</h2>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={toggle}
              />
              <label
                className="form-check-label"
                htmlFor="flexRadioDefault1"
                style={{ color: "#000" }}
              >
                Pay with Credit Card
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={toggle1}
              />
              <label
                className="form-check-label"
                htmlFor="flexRadioDefault2"
                style={{ color: "#000" }}
              >
                Pay with Bank Account
              </label>
            </div>
            {isOpened && (
              <div className="mt-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="card_number"
                    placeholder="Credit Card Number*"
                    name="card_number"
                  />
                </div>

                <div className="form-group">
                  <select
                    name="expiration_month"
                    id="MemberPaymentCcExpirationMonth"
                  >
                    <option value="" selected="selected">
                      Expiration Month *
                    </option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>

                <div className="form-group">
                  <select
                    name="expiration_month"
                    id="MemberPaymentCcExpirationMonth"
                  >
                    <option value="" selected="selected">
                      Expiration Year *
                    </option>
                    {yearList}
                  </select>
                </div>
                <p style={{ color: "#000" }}>
                  By clicking the button below I authorize Land Preserve LLC to
                  charge my credit card starting on May 24, 2022 and each month
                  following, for the amount of $10.00. I understand that I can
                  cancel at any time.
                </p>
              </div>
            )}
            {isOpened1 && (
              <div className="mt-3">
                <div className="form-group">
                  <select
                    name="member_payment[bank_account][account_type]"
                    id="MemberPaymentBankAccountAccountType"
                  >
                    <option value="" selected="selected">
                      Account Type *
                    </option>
                    <option value="checking">Checking</option>
                    <option value="savings">Savings</option>
                  </select>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="routing_number"
                    placeholder="Routing Number*"
                    name="routing_number"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="account_number"
                    placeholder="Account Number*"
                    name="account_number"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="name_on_account"
                    placeholder="Name On Account*"
                    name="name_on_account"
                  />
                </div>
                <p style={{ color: "#000" }}>
                  By clicking the button below I authorize Land Preserve LLC to
                  charge my credit card starting on May 24, 2022 and each month
                  following, for the amount of $10.00. I understand that I can
                  cancel at any time.
                </p>
              </div>
            )}
            <input className="mt-2" type="submit" value="Submit" />
          </div>
        </div>
      </Dashboardcss>
      <ToastContainer />
    </div>
  );
};
export default Dashboard;
