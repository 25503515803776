import styled from 'styled-components'
export const Homeform = styled.article`
.form-header {
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3125em;
    line-height: 0.85714em;
}
.form-header .subtitle {
    font-size: 0.57143em;
    line-height: 2em;
}
.l-page-title .l-page-title-text {
    background-color: white;
    display: inline-block;
    max-width: 74.64789%;
    padding: 0 1em;
    font-size: 50px;
    line-height: 1.5em;
    text-align: center;
    text-transform: uppercase !important;
    color: #635855;
    font-family: "AvenirNext", sans-serif;
    font-weight: normal;

    margin-bottom: 0.75em;
    font-weight: 300;
}
.header {
    display: block;
    padding-top: 0px;
    max-width: 300px;
    margin: 2em auto 0 auto;
    position: relative;
    z-index: 10;
}
.subtitle {
    display: block;
    letter-spacing: 4px;
}
.title-alt {
    color: #fff5e9;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.125em;
    font-size: 3em;
    font-family: "AvenirNextBold", sans-serif;
}

#about-our-story {
    display: block;
    margin: 3em auto 0 auto;
}
#about-join-us {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 0.375em 0;
    max-width: 400px;
    margin: 1.5em auto 0 auto;
}
#about-join-us, #about-scroll {
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-family: "Calisto MT", serif;
    letter-spacing: 9px;
    font-size: 0.875em;
    line-height: 1.71429em;
}
#about-scroll {
    margin-top: 4.5em;
    text-indent: 6px;
    padding-bottom: 2.25em;
    background: url(https://lp.shadowcomm.com/img/arrow-scroll.png) 50% bottom no-repeat;
}
input::placeholder {
    color: #fff;
    opacity: 1;
}
h2 {
    font-size: 2.25em;
    line-height: 1.33333em;
}
}`;