import React from "react";
import Logo from "../../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { Accountcss } from "./styles.js";

const Account = () => {
  const onSubmit = (data) => {
    console.log(data);
  }
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  return (
    <div className="l-page-body l-page-body-headless mt-4">
      <Accountcss>
        <img src={Logo} className="header" />
        <h1 className="l-page-title mt-2">
          <span className="l-page-title-text">EDIT ACCOUNT</span>
        </h1>
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter email*"
                  name="email"
                  {...register("email", {
                    required: "Email is required",

                    pattern: {
                      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email address"
                    }
                  })}
                />
                {errors.email && <div className="help-block">{errors.email.message}</div>}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password*"
                  name="password"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="password_confirmation"
                  placeholder="Confirm Password*"
                  name="password_confirmation"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  name="phone"
                />
              </div>
              <div className="form-group">



              </div>
              <input type="submit" defaultValue="Save" />
            </form>
          </div>
        </div>
      </Accountcss>
    </div>
  );
};
export default Account;
