import React, { Component } from "react";
import { Aboutcss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import Story from "../../assets/images/our-story.png";
import { Helmet } from "react-helmet";
import { pagesService } from "../../services/pages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userService } from "../../services/user.service";

import "react-toastify/dist/ReactToastify.css";
export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagedetail: [],
      email: "",
      name: "",
      deparment_id: "",
      message: "",

    };
    this.doContact = this.doContact.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  Department() {
    pagesService.getDepartment()
      .then(
        res => {
          if (res.status === 'success') {
            this.setState({ pagedetail: res.landparcels });
          } else {
            toast.error("Something went wrong", "Dashboard");
          }
        },
        error => {
          toast.error("Something went wrong", "Dashboard");
        }
      )
  };
  async doContact(event) {
    event.preventDefault();
    const { email, name, deparment_id, message } = this.state;
    userService.contactDepartment(email, name, deparment_id, message).then(
      (res) => {
        if (res.status == "success") {

          toast.success(res.message);
          this.setState({ email: '' });
          this.setState({ deparment_id: '' });
          this.setState({ name: '' });
          this.setState({ message: '' });
        } else {

          toast.error(res.message, "Contact");
        }
      },
      (error) => {
        localStorage.removeItem("userDetails");
        toast.error("Invalid Credetials !!", "Login");
        //this.clearLoginForm();
      }
    );
  }
  componentDidMount() {
    this.Department();
  }
  render() {
    const myStyle = {
      backgroundImage: `url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      marginTop: "48px",
    };


    return (
      <div style={myStyle}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Contact Us | Land Preservation Club | Environment Protection
          </title>
          <link rel="canonical" href="/" />
        </Helmet>
        <div className="l-page-body l-page-body-headless ">
          <Aboutcss>
            <img src={Logo} className="header" />
            <form className="mt-5" onSubmit={this.doContact}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="deparment_id"
                      required
                      onChange={this.handleChange}
                      defaultValue={'DEFAULT'}
                    >
                      <option value="DEFAULT" disabled>Department</option>
                      {
                        this.state.pagedetail?.map((x, y) =>
                          <option key={y} value={x.id}>{x.name}</option>
                        )};
                    </select>
                  </div>
                </div>
                <div className="col-md-5">

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      name="name"
                      onChange={this.handleChange}
                      value={this.state.name}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Email"
                      name="email"
                      onChange={this.handleChange}
                      value={this.state.email}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea onChange={this.handleChange} class="form-control" id="exampleFormControlTextarea1" rows="3" name="message" value={this.state.message} required></textarea>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#f7931e",
                      border: "none",
                      float: "right",
                    }}
                  >
                    Contact Us
                  </button>
                </div>
                <div className="col-md-3"></div>
              </div>
            </form>
            <ToastContainer />
          </Aboutcss>
        </div>
      </div>
    );
  }
}

export default Contact;
