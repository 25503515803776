import React, {useState} from "react";
import Logo from "../../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { Paymentcss } from "./styles.js";
import State from "../../components/State";
import Country from "../../components/Country";

const Account = () => {
  const onSubmit = (data) => {
    console.log(data);
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  let minOffset = 0,
   maxOffset = 20;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear + x);
  }

  const yearList = allYears.map((x) => {
    return <option key={x}>{x}</option>;
  });
  const [isOpened, setIsOpened] = useState(false);
  const [isOpened1, setIsOpened1] = useState(false);
  function toggle() {
    setIsOpened(true);
    setIsOpened1(false);
  }
  function toggle1() {
    setIsOpened1(true);
    setIsOpened(false);
  }
  return (
    <div className="l-page-body l-page-body-headless mt-4">
      <Paymentcss>
        <img src={Logo} className="header" />

        <div className="row">
          <div className="col-md-6">
            <h2 className="column-header form-header form-header-dark">
              Billing<span className="subtitle">Address</span>
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName"
                  placeholder="Enter name*"
                  name="name"
                />
                {errors.name && (
                  <div className="help-block">{errors.name.message}</div>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  placeholder="Last name*"
                  name="last_name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="company_name"
                  placeholder="Company Name*"
                  name="company_name"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Address *"
                  name="address"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="address_2"
                  placeholder="Address 2"
                  name="address_2"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City*"
                  name="city"
                />
              </div>
              <div className="form-group">
                <State />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="postal_code"
                  placeholder="Postal Code *"
                  name="postal_code"
                />
              </div>
              <div className="form-group">
                <Country />
              </div>
              <div className="form-group"></div>
              {/* <input type="submit" defaultValue="Save" /> */}
            </form>
          </div>
          <div className="col-md-6">
            <h2 className="column-header form-header form-header-dark">
              PAYMENT<span className="subtitle">INFORMATION</span>
            </h2>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onClick={toggle}
              />
              <label
                className="form-check-label"
                htmlFor="flexRadioDefault1"
                style={{ color: "#000" }}
              >
                Pay with Credit Card
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={toggle1}
              />
              <label
                className="form-check-label"
                htmlFor="flexRadioDefault2"
                style={{ color: "#000" }}
              >
                Pay with Bank Account
              </label>
            </div>
            {isOpened && (
            <div className="mt-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="card_number"
                  placeholder="Credit Card Number*"
                  name="card_number"
                />
              </div>

              <div className="form-group">
                <select
                  name="expiration_month"
                  
                  id="MemberPaymentCcExpirationMonth"
                >
                  <option value="" selected="selected">
                    Expiration Month *
                  </option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>

              <div className="form-group">
                <select
                  name="expiration_month"
                  id="MemberPaymentCcExpirationMonth"
                >
                  <option value="" selected="selected">
                    Expiration Year *
                  </option>
                  {yearList}
                </select>
              </div>
              <p style={{ color: "#000" }}>
                By clicking the button below I authorize Land Preserve LLC to
                charge my credit card starting on May 24, 2022 and each month
                following, for the amount of $10.00. I understand that I can
                cancel at any time.
              </p>
            </div>

            )}
            {isOpened1 && (
            <div className="mt-3">
              <div className="form-group">
                <select
                  name="member_payment[bank_account][account_type]"
                  id="MemberPaymentBankAccountAccountType"
                >
                  <option value="" selected="selected">
                    Account Type *
                  </option>
                  <option value="checking">Checking</option>
                  <option value="savings">Savings</option>
                </select>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="routing_number"
                  placeholder="Routing Number*"
                  name="routing_number"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="account_number"
                  placeholder="Account Number*"
                  name="account_number"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="name_on_account"
                  placeholder="Name On Account*"
                  name="name_on_account"
                />
              </div>
              <p style={{ color: "#000" }}>
                By clicking the button below I authorize Land Preserve LLC to
                charge my credit card starting on May 24, 2022 and each month
                following, for the amount of $10.00. I understand that I can
                cancel at any time.
              </p>
            </div>
            )}
            <input className="mt-2" type="submit" value="Submit" /> 
          </div>
          
        </div>
      </Paymentcss>
    </div>
  );
};
export default Account;
