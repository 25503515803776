import React, { Component } from "react";
import Header from "./Header";
import Header2 from "./Header2";
import Footer from "./Footer"
class App extends Component {
  render() {
    return (
      <div>

        {sessionStorage.getItem('token') ? <Header2  /> :<Header  /> }
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default App;
