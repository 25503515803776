import React, { Component } from "react";
import { Careerscss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import Container from "react-bootstrap/Container";
import { pagesService } from "../../services/pages.service";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
export class Careers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagedetail: [],
    };
  }
  componentDidMount() {
    pagesService.pagesId("6").then(
      (res) => {
        if (res.status === "success") {
          this.setState({ pagedetail: res.pagedetail });
        } else {
          toast.error("Something went wrong", "Dashboard");
        }
      },
      (error) => {
        toast.error("Something went wrong", "Dashboard");
      }
    );
  }
  render() {
    return (
      <>
        <Careerscss>
          <img src={Logo} className="header" />
          {this.state && !this.state.pagedetail.body ? (
            <p>Loding......</p>
          ) : (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.pagedetail.seotitle}</title>
                <link rel="canonical" href="/" />
              </Helmet>
              <h1 className="l-page-title mt-2">
                <span className="l-page-title-text">
                  {this.state.pagedetail.title}
                </span>
              </h1>
              <Container>
                <div className="l-page-body-content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.pagedetail.body,
                    }}
                  ></p>
                </div>
              </Container>
            </>
          )}
        </Careerscss>
      </>
    );
  }
}

export default Careers;
