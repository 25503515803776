import React, { useState, useEffect } from "react";
import { Landparcelscss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import { pagesService } from "../../services/pages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    useParams
} from "react-router-dom";
const LandparcelDetaile = () => {
    let { slug } = useParams();
    const [page, setPage] = useState({});
    useEffect(() => {
        pagesService.landparDetaile(slug).then(
            (res) => {
                if (res.status === "success") {
                    console.log(res);
                    setPage(res.landparcel);
                } else {
                    toast.error("Something went wrong", "Dashboard");
                }
            },
            (error) => {
                toast.error("Something went wrong", "Dashboard");
            }
        );
    }, []);
    console.log(page.name);
    return (

        <>

            <Landparcelscss>
                <div className="l-content-container">
                    <div className="l-page">
                        <img src={Logo} alt="Land Preserve LLC" className="header" />

                        {page && !page.body ? (
                            <p>Loding......</p>
                        ) : (
                            <div className="l-page-body">
                                <h1 className="l-page-title">
                                    <span className="l-page-title-text">{page.name}</span>
                                </h1>
                                <div
                                    className="l-page-body-content"
                                    style={{ color: "#6f6f6f" }}
                                >
                                    <p className="parcel-acreage" dangerouslySetInnerHTML={{ __html: page.body }}></p>

                                </div>
                            </div>
                        )}

                    </div>
                    <div className="tab-pane tab-pane-bottom" id="tab-pane-gmap"></div>
                </div>
            </Landparcelscss>
        </>
    );
}

export default LandparcelDetaile;
