import styled from 'styled-components'
export const Landparcelscss = styled.article`
.header {
    display: block;
    padding-top: 1.5em;
    max-width: 300px;
    margin: 3em auto 0 auto;
    position: relative;
    z-index: 10;
}
.l-page-title {
    color: #635855;
    font-family: "AvenirNext", sans-serif;
    font-weight: normal;
    margin-top: -0.5em;
    margin-bottom: 0.75em;
}
h1 {
    font-size: 3em;
    line-height: 1.5em;
    text-align: center;
    text-transform: uppercase;
}
.l-page-body-content {
    padding: 0 15.49296%;
}
.parcel-selectors {
    margin-bottom: 2em;
    border-bottom: 1px solid #5c4b57;
    text-align: right;
}
.parcel-selectors select, .parcel-selectors .customSelect {
    display: inline-block;
    width: 200px;
    margin-right: 12px;
    margin-bottom: 15px;
}
.l-page-body{
float: left;
margin-right: 1.40845%;
display: inline;
margin-right: 13.38028%;
margin-left: 13.38028%;
padding-bottom: 6em;
margin-top: 3em;
border-top: 1px solid #5c4a56;
}
.parcel-column .column-listing-header a {
    color: #f7931e;
    text-decoration: none;
    font-size: 20px;

}
.parcel-column .parcel-acreage {
    display: block;
    color: #231f20;
    font-size: 1.2em;
    margin-bottom: 0.5em;
}
.parcel-column .parcel-read-more {
    color: #231f20;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    margin-top: 8px;
}
.ac_que{
    color: #6f6f6f;
}
.l-page-title-text{
    background-color: white;
    display: inline-block;
    max-width: 74.64789%;
    padding: 0 1em;
}
.parcel-column .column-body {
    border: 1px solid #5c4b57;
    -webkit-border-radius: 12px 12px 0 0;
    -moz-border-radius: 12px 12px 0 0;
    -ms-border-radius: 12px 12px 0 0;
    -o-border-radius: 12px 12px 0 0;
    border-radius: 12px 12px 0 0;
    margin: 0 16px;
    padding: 16px;
}
.column.alpha {
    clear: both;
}
.columns-2 .column {
    width: 50%;
    float: left;
    margin-right: 1.88679%;
    display: inline;
    margin-right: 0;
    margin-left: 0;
}
.column {
    margin-bottom: 1.5em;
}
}`;