import React, { Component } from "react";
import { Logincss } from "./styles.js";
import { Navigate } from "react-router-dom";
// import { Link  } from 'react-router-dom';
import { history } from "../../services/history";
import Logo from "../../assets/images/logo.png";
import { userService } from "../../services/user.service";
import FromValidator from "../../_validator/formValidator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
export class Login extends Component {
  constructor(props) {
    super(props);

    this.validatorLogin = new FromValidator([
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Email is required",
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Enter valid email address",
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "password is required",
      },
    ]);

    this.state = {
      email: "",
      password: "",
      loginSubmitted: false,
      validationLogin: this.validatorLogin.valid(),
    };

    this.doLogin = this.doLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  async doLogin(event) {
    event.preventDefault();
    const validation = this.validatorLogin.validate(this.state, "");
    this.setState({ validationLogin: validation });

    this.setState({ loginSubmitted: true });
    const { email, password } = this.state;

    if (validation.isValid) {
      userService.login(email, password).then(
        (res) => {
          if (res.status == "success") {
            sessionStorage.setItem("token", res.token);
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.userdetails)
            );
            history.push("/members/myaccount");
            history.go("/members/myaccount");
            toast.success(res.message);
          } else {
            localStorage.removeItem("userDetails");
            toast.error(res.message, "Login");
          }
        },
        (error) => {
          localStorage.removeItem("userDetails");
          toast.error("Invalid Credetials !!", "Login");
          //this.clearLoginForm();
        }
      );
    }
  }
  render() {
    const { email, password, loginSubmitted } = this.state;
    let _validatorLogin = loginSubmitted
      ? this.validatorLogin.validate(this.state, "")
      : this.state.validationLogin;
    const myStyle = {
      backgroundImage: `url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      marginTop: "48px",
    };
    return (
      <div style={myStyle}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Members | Land Preservation Club | Environment Protection
          </title>
          <link rel="canonical" href="/" />
        </Helmet>
        <div className="l-page-body l-page-body-headless">
          <Logincss>
            <img src={Logo} className="header" />
            <h2 className="column-header form-header mt-5">
              LOGIN <span className="subtitle">EXISTING MEMBER</span>
            </h2>
            <form onSubmit={this.doLogin}>
              <div className="form-group">
                <input
                  type="text"
                  value={email}
                  onChange={this.handleChange}
                  name="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Email"
                />
                {_validatorLogin.email.isInvalid && (
                  <div className="help-block">
                    {_validatorLogin.email.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  value={password}
                  onChange={this.handleChange}
                  name="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                />
                {_validatorLogin.password.isInvalid && (
                  <div className="help-block">
                    {_validatorLogin.password.message}
                  </div>
                )}
              </div>
              <a href="/members/forgot" className="linkcolor">
                Forgot your password?
              </a>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#f7931e",
                  border: "none",
                  float: "right",
                }}
              >
                Login
              </button>
            </form>
          </Logincss>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Login;
