import React, { Component } from "react";
import { Homeform } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginCom from "../login";
import RegisterCom from "../Register";
export class HomeForm extends Component {
  render() {
    const myStyle = {
      backgroundImage: `url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "170vh",
      marginTop: "48px",
    };
    return (
      <div style={myStyle}>
        <div className="l-page-body l-page-body-headless">
          <Homeform>
            <img src={Logo} className="header" />
            <div className="row">
              <div className="col-md-6">
                <RegisterCom />
              </div>
              <div className="col-md-6">
                <LoginCom />
              </div>
            </div>
          </Homeform>
          <ToastContainer />
        </div>

      </div>
    );
  }
}

export default HomeForm;
