import React, { useState } from "react";
import { Registercss } from "./styles.js";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/images/logo.png";
import { userService } from "../../services/user.service";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

const Register = () => {
  const myStyle = {
    backgroundImage: `url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "130vh",
    marginTop: "45px",
  };

  const onSubmit = (data) => {
    userService.register(data).then(
      (res) => {

        if (res.status == "success") {
          reset();
          toast.success(
            "Registration has been done successfully !!",
            "Registration"
          );
          //this.clearRegForm();
        } else {
          toast.error(res.message["0"], "Registration");
        }
      },
      (error) => {
        toast.error("Something went wrong", "Registration");
      }
    );
  };

  // useEffect(() => {
  //   validationReg: validatorReg.valid(),

  // });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  return (
    <div style={myStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join Us | Land Preservation Club | Environment Protection</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="l-page-body l-page-body-headless">
        <Registercss>
          <img src={Logo} className="header" />
          <h2 className="column-header form-header mt-4">
            REGISTER <span className="subtitle">NEW MEMBER</span>
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                id="exampleFormControlInput1"
                placeholder="Name"
                {...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 3,
                    message: "Name must have at least 3 characters",
                  },
                })}
              />
              {errors.name && (
                <div className="help-block">{errors.name.message}</div>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="email"
                id="exampleFormControlInput1"
                placeholder="Email"
                {...register("email", {
                  required: "Email is required",

                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <div className="help-block">{errors.email.message}</div>
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                name="password"
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && (
                <div className="help-block">{errors.password.message}</div>
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password_confirmation"
                className="form-control"
                id="PasswordConfirm"
                placeholder="Password Confirm"
                {...register("password_confirmation", {
                  required: "Password Confirm is required",
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              {errors.password_confirmation && (
                <div className="help-block">
                  {errors.password_confirmation.message}
                </div>
              )}
            </div>

            <div className="form-group form-check">
              <input
                type="checkbox"
                name="term"
                className="form-check-input"
                id="exampleCheck1"
                value="1"
                {...register("term", {
                  required: "Terms and Conditions is required",
                })}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                I agree to the{" "}
                <Link
                  target={"_blank"}
                  to="/terms-and-conditions"
                  className="linkcolor"
                >
                  {" "}
                  Terms and Conditions
                </Link>
              </label>
              {errors.term && (
                <div className="help-block">{errors.term.message}</div>
              )}
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="license"
                className="form-check-input"
                id="exampleCheck2"
                value="1"
                {...register("license", {
                  required: "Land Use License Agreement is required",
                })}
              />
              <label className="form-check-label" htmlFor="exampleCheck2">
                I agree to the{" "}
                <Link
                  target={"_blank"}
                  to="/land-use-license-agreement"
                  className="linkcolor"
                >
                  {" "}
                  Land Use License Agreement
                </Link>{" "}
              </label>
              {errors.license && (
                <div className="help-block">{errors.license.message}</div>
              )}
            </div>
            <input
              type="hidden"
              name="tc"
              className="form-control"
              id="referring"
              value="true"
              {...register("tc")}
            />
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#f7931e",
                border: "none",
                float: "right",
              }}
            >
              Register
            </button>

            <div className="form-group mt-5">
              <input
                type="text"
                name="referring_member_code"
                className="form-control"
                id="referring"
                placeholder="Referring Member Code"
              />
              <Link to="#"> What's this?</Link>
            </div>
            <div>
              <label className="form-check-label" htmlFor="exampleCheck1">
                Existing user?
                <Link to="/members/login" className="linkcolor">
                  {" "}
                  sign in
                </Link>{" "}
                here
              </label>
            </div>
          </form>
        </Registercss>
        <ToastContainer />
      </div>
    </div>
  );
};
export default Register;
