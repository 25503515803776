import React, { Component } from "react";
import { Headercss } from "./styles.js";
import { Link } from "react-router-dom";
export class Header extends Component {
  render() {
    return (
      <Headercss>
        <div className="l-menu-container l-menu-container-top">
          <ul className="menu menu-count-5">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/land-parcels">Land Portfolio</Link>
            </li>
            <li>
              <Link to="/members/register"> Join Us</Link>
            </li>
            <li>
              <Link to="/members/login"> Login</Link>
            </li>
          </ul>
        </div>
      </Headercss>
    );
  }
}

export default Header;
