// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { Navigate } from 'react-router-dom';
// import Layouts from "../components/app";
// export const PrivateRoute = ({ component: Component, ...rest }) => {
//     return <Route {...rest} render={props => {
//         return localStorage.getItem('userDetails') ?
//             <Layouts><Component /></Layouts>
//             :   <Navigate to="/members/login" />
//     }} />
// };



import React from "react"; 
import { Navigate } from "react-router-dom"; 


const PrivateRoute= ({ children }) => {
return sessionStorage.getItem('token') ? children : <Navigate to={"/members/login"} /> 
}

export default PrivateRoute;