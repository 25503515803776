import React, { useState, useEffect } from "react";
import { Careerscss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import { pagesService } from "../../services/pages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
const GlobalComponent = (props) => {
    const [page, setPage] = useState([]);

    useEffect(() => {
        pagesService.pagesId(props.data).then(
            (res) => {
                if (res.status === "success") {
                    setPage(res.pagedetail);
                } else {
                    toast.error("Something went wrong", "Dashboard");
                }
            },
            (error) => {
                toast.error("Something went wrong", "Dashboard");
            }
        );
    });
    return (
        <>
            <Careerscss>
                <div className="l-content-container">
                    <div className="l-page">
                        <img src={Logo} className="header" />
                        {page && !page.body ? (
                            <p>Loding......</p>
                        ) : (
                            <>
                                <div className="l-page-body" style={{ color: "#6f6f6f" }}>
                                    <Helmet>
                                        <meta charSet="utf-8" />
                                        <title>{page.seotitle}</title>
                                        <link rel="canonical" href="/" />
                                    </Helmet>
                                    <h1 className="l-page-title">
                                        <span className="l-page-title-text">{page.title}</span>
                                    </h1>
                                    <Container>
                                        <div className="l-page-body-content" >
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: page.body,
                                                }}
                                            ></p>
                                        </div>
                                    </Container>
                                </div>
                            </>
                        )}


                    </div>
                </div>
            </Careerscss>
        </>
    );
};
export default GlobalComponent;
