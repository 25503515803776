import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userService } from "../../services/user.service";
const RegisterCom = () => {

  const onSubmit = (data) => {
    userService.register(data).then(
      (res) => {

        if (res.status == "success") {
          reset();
          toast.success(
            "Registration has been done successfully !!",
            "Registration"
          );
          //this.clearRegForm();
        } else {
          toast.error(res.message["0"], "Registration");
        }
      },
      (error) => {
        toast.error("Something went wrong", "Registration");
      }
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  return (

    <>
      <h2
        className="column-header form-header"
        style={{ paddingRight: "3em" }}
      >
        Register <span className="subtitle">New Member</span>
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            borderRight: "1px dashed white",
            paddingRight: "3em",
          }}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="name"
              id="exampleFormControlInput1"
              placeholder="Name"
              {...register("name", {
                required: "Name is required",
                minLength: {
                  value: 3,
                  message: "Name must have at least 3 characters",
                },
              })}
            />
            {errors.name && (
              <div className="help-block">{errors.name.message}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Email"
              {...register("email", {
                required: "Email is required",

                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <div className="help-block">{errors.email.message}</div>
            )}
          </div>


          <div className="form-group">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              name="password"
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && (
              <div className="help-block">{errors.password.message}</div>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password_confirmation"
              className="form-control"
              id="PasswordConfirm"
              placeholder="Password Confirm"
              {...register("password_confirmation", {
                required: "Password Confirm is required",
                validate: (val: string) => {
                  if (watch("password") != val) {
                    return "Your passwords do no match";
                  }
                },
              })}
            />
            {errors.password_confirmation && (
              <div className="help-block">
                {errors.password_confirmation.message}
              </div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="Phone"
              name="phone"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="referring"
              placeholder="Referring Member Code"
            />
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              name="term"
              className="form-check-input"
              id="exampleCheck1"
              value="1"
              {...register("term", {
                required: "Terms and Conditions is required",
              })}
            />
            <label
              className="form-check-label"
              htmlFor="exampleCheck1"
            >
              I agree to the{" "}
              <Link
                target={"_blank"}
                to="/terms-and-conditions"
                className="linkcolor"
              >
                {" "}
                Terms and Conditions
              </Link>
            </label>
            {errors.term && (
              <div className="help-block">{errors.term.message}</div>
            )}
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              name="license"
              className="form-check-input"
              id="exampleCheck2"
              value="1"
              {...register("license", {
                required: "Land Use License Agreement is required",
              })}
            />
            <label
              className="form-check-label"
              htmlFor="exampleCheck1"
            >
              I agree to the{" "}
              <Link
                target={"_blank"}
                to="/land-use-license-agreement"
                className="linkcolor"
              >
                {" "}
                Land Use License Agreement
              </Link>{" "}
              {errors.license && (
                <div className="help-block">{errors.license.message}</div>
              )}
            </label>
            <input
              type="hidden"
              name="tc"
              className="form-control"
              id="referring"
              value="true"
              {...register("tc")}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{
            backgroundColor: "#f7931e",
            border: "none",
            marginRight: "3em",
            float: "right",
          }}
        >
          Register
        </button>
      </form>
      <ToastContainer />
    </>
  );
}
export default RegisterCom;