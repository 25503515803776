import React from "react";
import { useForm } from 'react-hook-form';
import { userService } from "../../services/user.service";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
const LoginCom = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    userService.loginas(data).then(
      (res) => {
        if (res.status == "success") {
          sessionStorage.setItem("token", res.token);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(res.userdetails)
          );
          navigate("/members/myaccount");
          // history.push("/members/myaccount");
          // history.go("/members/myaccount");
          toast.success(res.message);
        } else {
          localStorage.removeItem("userDetails");
          toast.error(res.message, "Login");
        }
      },
      (error) => {
        localStorage.removeItem("userDetails");
        toast.error("Invalid Credetials !!", "Login");
        //this.clearLoginForm();
      }
    );
  }
  return (
    <>
      <h2 className="column-header form-header">LOGIN <span className="subtitle">EXISTING MEMBER</span></h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingLeft: "3em" }}>
          <div className="form-group">
            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <div className="help-block">{errors.email.message}</div>
            )}
          </div>
          <div className="form-group">
            <input type="password" className="form-control" id="password" placeholder="Password" name="password"  {...register("password", { required: "Password is required" })} />
            {errors.password && (
              <div className="help-block">{errors.password.message}</div>
            )}
          </div>
          <a href="/members/forgot" className="linkcolor">Forgot your password?</a>
        </div>
        <button type="submit" className="btn btn-primary" style={{ backgroundColor: "#f7931e", border: "none", float: "right" }}>Login</button>

      </form>
      <ToastContainer />
    </>
  );
}
export default LoginCom;