import styled from 'styled-components'
export const Logincss = styled.article`
.form-header .subtitle {
    font-size: 0.57143em;
    line-height: 2em;
}
.subtitle {
    display: block;
    letter-spacing: 4px;
}
h2.column-header.form-header {
    text-align: center;
}
input::placeholder {
    color: #fff;
    opacity: 1;
}
}`;