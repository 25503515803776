import React, { Component } from "react";
import { Headercss } from "./styles.js";
import { history } from "../../services/history";
import { Link } from "react-router-dom";
export class Header extends Component {
  clickHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
    history.go("/");
  };
  render() {
    return (
      <Headercss>
        <div className="l-menu-container l-menu-container-top">
          <ul className="menu menu-count-5">
            <li>
              <Link to="/members/myaccount">Dashboard</Link>
            </li>
            <li>
              <Link to="/members/account">Account</Link>
            </li>
            <li>
              <Link to="/members/payment">Payment</Link>
            </li>

            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="#" onClick={this.clickHandler}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </Headercss>
    );
  }
}

export default Header;
