import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./components/app";
import LandHome from "./pages/Home";
// import About from "./pages/About";
// import Faqs from "./pages/Faqs";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Payment from "./pages/Payment";
import Careers from "./pages/Careers";
import Landparcels from "./pages/Landparcels";
import TermsUser from "./pages/TermsUser";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Changepassword from "./pages/resetPassword";
import LandparcelsDetaile from "./pages/Landparcels/LandparcelDetaile";
import { history } from "./services/history";
import Termsandconditions from "./pages/Termsandconditions";
import Landuselicenseagreement from "./pages/Landuselicenseagreement";
import PrivateRoute from "./_privateRoute/index";
import { menuOptions } from "./data"
import GlobalComponent from "./pages/GlobalComponent"
function App() {
  return (
    <>
      <BrowserRouter history={history}>
        <Layout>
          <Routes>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              element={<LandHome />}
            />

            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              element={<About />}
            /> */}

            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/faqs`}
              element={<Faqs />}
            /> */}

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              element={<Contact />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
              element={<Termsandconditions />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/land-use-license-agreement`}
              element={<Landuselicenseagreement />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/login`}
              element={<Login />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/register`}
              element={<Register />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/reset/:token`}
              element={<Changepassword />}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/forgot`}
              element={<Forgot />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/careers`}
              element={<Careers />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/land-parcels`}
              element={<Landparcels />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/terms-of-use`}
              element={<TermsUser />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/land-parcels/:slug`}
              element={<LandparcelsDetaile />}
            />

            {/*
           <Route path={`${process.env.PUBLIC_URL}/members/dashboard`} element={<PrivateRoute Component={Dashboard} />} />
           <Route path="/" element={<PrivateRoute/>}>
            <Route path={`${process.env.PUBLIC_URL}/members/dashboard`} element={<Dashboard/>}/>
          </Route> */}
            {/* <PrivateRoute
              exact
              path={`${process.env.PUBLIC_URL}/members/dashboard`}
              element={<Dashboard />}
            /> */}

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/myaccount`}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/account`}
              element={
                <PrivateRoute>
                  <Account />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/members/payment`}
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />

            {menuOptions.map((menuOptions, index) => (

              <Route
                key={index}
                exact
                path={`${process.env.PUBLIC_URL}/${menuOptions.link}`}
                element={<GlobalComponent data={menuOptions.pageid} />}
              />
            ))}
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
