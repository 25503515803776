import Global from "../helpers";

export const pagesService = {
  pagesId,
  getDepartment,
  pagesLandparcels,
  landparDetaile

};
async function pagesId(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(Global.BASE_API_PATH + `page-details/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function landparDetaile(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(Global.BASE_API_PATH + `landpreserve-details/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}


async function getDepartment() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(Global.BASE_API_PATH + `deparments`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function pagesLandparcels() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(Global.BASE_API_PATH + `landpreserve`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}



function handleResponse(response) {
  return response.text().then((text) => {
    const data = JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
      }

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}
