import Global from '../helpers';

export const dashboardService = {
    getmemberdetailsById,
    getStates,
    getCountry,
};

async function getmemberdetailsById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("token")
                }`
        },
    };

    return fetch(Global.BASE_API_PATH + `memberdetails/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

async function getStates() {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };
    return fetch(Global.BASE_API_PATH + `states`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}



async function getCountry() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };
    return fetch(Global.BASE_API_PATH + `country`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;

            return Promise.reject(error);
        }

        return data;
    });
}