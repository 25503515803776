import styled  from 'styled-components'
export const Termsandconditionscss = styled.article`
.header {
    display: block;
    padding-top: 1.5em;
    max-width: 300px;
    margin: 3em auto 0 auto;
    position: relative;
    z-index: 10;
}
.l-page-title {
    color: #635855;
    font-family: "AvenirNext", sans-serif;
    font-weight: normal;
    margin-top: -0.5em;
    margin-bottom: 0.75em;
}
h1 {
    font-size: 3em;
    line-height: 1.5em;
    text-align: center;
    text-transform: uppercase;
}
.l-page-body-content {
    padding: 0 15.49296%;
}
}`;