import Global from '../helpers';

export const userService = {
    login,
    register,
    getmemberdetailsById,
    forgetPassword,
    loginas,
    contactDepartment,
    resetPassword

};


async function login(email, password) {
    const requestOptions = {
        method: 'POST',
        //headers: { 'Accept': 'application/json','Content-Type': 'application/json' },
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(Global.BASE_API_PATH + `login`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
async function contactDepartment(email, name, deparment_id, message) {
    const requestOptions = {
        method: 'POST',
        //headers: { 'Accept': 'application/json','Content-Type': 'application/json' },
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, name, deparment_id, message })
    };

    return fetch(Global.BASE_API_PATH + `contact`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

async function loginas(obj) {
    const requestOptions = {
        method: 'POST',
        //headers: { 'Accept': 'application/json','Content-Type': 'application/json' },
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };

    return fetch(Global.BASE_API_PATH + `login`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

async function register(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };

    return fetch(Global.BASE_API_PATH + `register`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

async function forgetPassword(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };

    return fetch(Global.BASE_API_PATH + `forgot`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}
async function resetPassword(token, obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };

    return fetch(Global.BASE_API_PATH + `resetpassword/${token}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

async function getmemberdetailsById(id) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(Global.BASE_API_PATH + `memberdetails/${id}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;

            return Promise.reject(error);
        }

        return data;
    });
}