import styled  from 'styled-components'
export const Headercss = styled.article`
    .l-menu-container-top {
        top: 0;
    }
    .menu li:hover {
        background-color: #f47a00;
    }
    .menu li:first-child a {
        border-left: 0 none;
    }
    .menu li:last-child a {
        border-right: 0 none;
    }
    .l-menu-container {
        overflow: hidden;
        *zoom: 1;
        width: 100%;
        height: 3em;
        background-color: #f7931e;
        position: fixed;
        bottom: 0;
        z-index: 2000;
    }
    .menu a {
        font-family: "Calisto MT", serif;
        text-decoration: none;
        text-transform: capitalize;
        color: #fff5e9;
        display: block;
        cursor: pointer;
        height: 100%;
        letter-spacing: 1px;
        font-size: 0.875em;
        line-height: 0em;
        line-height: 100%;
        padding-top: 1.2em;
        padding-left: 18px;
        padding-right: 18px;
        border-right: 1px solid #ba5800;
        border-left: 1px solid #f4ad67;
    }
    .menu {
        height: 100%;
        *zoom: 1;
        max-width: 60em;
        _width: 60em;
        padding-left: 0em;
        padding-right: 0em;
        margin: 0 auto;
        text-align: center;
    }
    .menu.menu-count-5 li {
        width: 20%;
    }
    .menu li {
        height: 100%;
        /* float: left; */
        display: inline-block;
        background-color: #f7931e;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
        -webkit-transition: background-color 300ms;
        -moz-transition: background-color 300ms;
        -o-transition: background-color 300ms;
        transition: background-color 300ms;
    }
    
    
}`;