import React from "react";
import { dashboardService } from "../../services/dashboard.service";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class State extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {person: []};
    }
  
    componentDidMount() {
      this.StateList();
    }
  
    StateList() {
        dashboardService.getStates()
      .then(
        res => {
          if (res.status === 'success') {
            this.setState({ person: res.states })
          } else {
            toast.error("Something went wrong", "Dashboard");
          }
        },
        error => {
          toast.error("Something went wrong", "Dashboard");
        }
      )


 
    }
  
    render() {
      const persons = this.state.person.map((item, i) => (
        <option key={i} value={item.id}>{item.name}</option>
      ));
  
      return (
        <select
        className="form-select"
        name="state"
        aria-label="Default select example"
      >
        { persons }
      </select>
      );
    }
  }