import React, { Component } from "react";
import { Logincss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import Story from "../../assets/images/our-story.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { userService } from "../../services/user.service";
const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const myStyle = {
    backgroundImage: `url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    marginTop: "48px",
  }
  const onSubmit = (data) => {

    userService.forgetPassword(data).then(
      (res) => {
        console.log(res);
        if (res.status == "success") {
          reset();
          toast.success(
            res.message,
            "Registration"
          );

        } else {
          toast.error(res.message, "Registration");
        }
      },
      (error) => {
        toast.error("Email doesnt exists", "Registration");
      }
    );
  };
  return (
    <div style={myStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Forgot
        </title>
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="l-page-body l-page-body-headless">
        <Logincss>
          <img src={Logo} className="header" />
          <h2 className="column-header form-header mt-5">FORGOT YOUR PASSWORD?</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email"
                {...register("email", {
                  required: "Email is required",

                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <div className="help-block">{errors.email.message}</div>
              )}
            </div>

            <button type="submit" className="btn btn-primary" style={{ backgroundColor: "#f7931e", border: "none", float: "right" }}>Send Reset Request</button>
          </form>
        </Logincss>
        <ToastContainer />
      </div>
    </div>
  );

}

export default Login;
