import styled  from 'styled-components'
export const Accountcss = styled.article`
.l-page-title {
    color: #635855;
    font-family: "AvenirNext", sans-serif;
    font-weight: normal;
    margin-top: -0.5em;
    margin-bottom: 0.75em;
    text-align: center;
}
.account{
    color: #635855;
}
.begin{
    color: #635855;
}
a.edit.btn {
    background-color: #f7931e;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    -webkit-transition: background-color 300ms;
    -moz-transition: background-color 300ms;
    -o-transition: background-color 300ms;
    transition: background-color 300ms;
    color: white;
}
input[type=text], input[type=email], input[type=password], input[type=submit], textarea {
    border: none;
    color: white;
    /* background-color: rgba(255, 255, 255, 0.3); */
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    padding: 0.75em 0.5em;
    margin: 0 0 0.75em 0;
    font-family: "AvenirNext", sans-serif;
    width: 100%;
    font-size: inherit;
    border: 1px inset #6f6f6f;
    color: #6f6f6f;
}
}`;