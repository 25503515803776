import React, { Component } from "react";
import { Footerrcss } from "./styles.js";
import { Link } from "react-router-dom";
export class Footer extends Component {
  render() {
    return (
      <>
      <Footerrcss>
        <div className="l-menu-container">
          <ul className="menu menu-count-6">
            <li>
              {/* <a href="/contact" title>
                Contact
              </a> */}
              <Link to="/contact"> Contact</Link>
            </li>
            <li>
              {/* <a href="/faqs" title>
                FAQs
              </a> */}
              <Link to="/faqs"> FAQs</Link>
            </li>
            <li>
              {/* <a href="/" title>
                Terms of Use
              </a> */}
               <Link to="/terms-of-use"> Terms of Use</Link>
            </li>
            <li>
              {/* <a href="/" title>
                Privacy Policy
              </a> */}
              <Link to="/privacy-policy">  Privacy Policy</Link>
            </li>
            <li>
              {/* <a href="/members/login" title>
                Staff Login
              </a> */}
              <Link to="/members/login">  Staff Login</Link>
            </li>
            <li>
              {/* <a href="/member/members/myaccount#refer" title>
                Refer a New Member
              </a> */}
               <Link to="/members/login">   Refer a New Member</Link>
            </li>
          </ul>{" "}
        </div>
        </Footerrcss>
      </>
    );
  }
}

export default Footer;
