import React, { Component } from "react";
import { Landparcelscss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import Container from "react-bootstrap/Container";
import Accordions from "../../components/AccordionItem";
import { pagesService } from "../../services/pages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";

// const App = () => {
//     return <ul>{[...Array(3)].map((e, i) => {
//       return <SmallBox key={i}  title={`Accordion Item # ${i}`}  dec={`Accordion Item # ${i}`}   no={i}/>
//     })}</ul>
// }

export class Landparcels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagedetail: [],
    };
  }
  componentDidMount() {
    pagesService.pagesLandparcels().then(
      (res) => {
        if (res.status === "success") {

          this.setState({ pagedetail: res.landparcels });
        } else {
          toast.error("Something went wrong", "Dashboard");
        }
      },
      (error) => {
        toast.error("Something went wrong", "Dashboard");
      }
    );
  }
  render() {
    return (
      <>
        <Landparcelscss>
          {/* <img src={Logo} className="header" />
          <h1 className="l-page-title mt-2">
            <span className="l-page-title-text">OUR LAND PARCELS</span>
          </h1>
          {/* <Accordions /> */}
          {/* <Container>
            <div className="parcel-selectors">
              <select name="country" id="select_country">
                <option value selected="selected">
                  Select Country
                </option>
                <option value={1}>United States of America</option>
              </select>{" "}
              <select name="state" id="select_state">
                <option value selected="selected">
                  Select State
                </option>
                <option value={7}>Colorado</option>
              </select>{" "}
              <a href="#list-view" className="icon parcel-icons-list active" />
              <a
                href="/land-parcels/map"
                className="icon parcel-icons-map"
              />{" "}
            </div>
            <div className="row">
              {this.state && !this.state.pagedetail ? (
                <p>Loding......</p>
              ) : (
                <>
                  {this.state.pagedetail?.map((x, y) => (
                    <div className="card col-md-6">
                      <div className="row no-gutters">
                        <div className="card-body" key={y}>
                          <h5 className="card-title">{x.name}</h5>
                          <p>5 Acres — Acquired</p>
                          <p className="card-text">{x.locationDescription}</p>
                          <NavLink key={x.id} to={`/land-parcels/${x.id}`}>
                            READ MORE
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Container> */}

          <div className="l-content-container">
            <div className="l-page">
              <img src={Logo} className="header" alt="Land Preserve LLC" />
              <div className="l-page-body">
                <h1 className="l-page-title">
                  <span className="l-page-title-text">Our Land Parcels</span>
                </h1>
                <div className="parcel-selectors">
                  <select name="country" id="select_country" defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" disabled selected="selected">
                      Select Country
                    </option>
                    <option value={1}>United States of America</option>
                  </select>
                  <select name="state" id="select_state" defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" disabled selected="selected">
                      Select State
                    </option>
                    <option value={7}>Colorado</option>
                  </select>
                  <a
                    href="#list-view"
                    className="icon parcel-icons-list active"
                  />
                  <a
                    href="/land-parcels/map"
                    className="icon parcel-icons-map"
                  />
                </div>
                <div id="list-view">
                  {this.state && !this.state.pagedetail ? (
                    <p>Loding......</p>
                  ) : (
                    <div className="columns columns-2">
                      <div className="parcel-column column country-1 state-7 alpha">
                        <div className="column-body">
                          <>
                            {this.state.pagedetail?.map((x, y) => (
                              <div className="column-listing" key={y}>
                                <h3 className="column-listing-header">
                                  {/* <a href="/land-parcels/native-silver">
                                    {x.name}
                                  </a> */}
                                  <NavLink
                                    key={x.id}

                                    to={`/land-parcels/${x.slug}`}
                                  >
                                    {x.name}
                                  </NavLink>
                                </h3>
                                <p>
                                  <span className="parcel-acreage">
                                    {x.acreage} Acres &nbsp;— &nbsp;
                                    <span className="is_acquired">
                                      Acquired
                                    </span>
                                  </span>
                                  <p className="ac_que">{x.locationDescription}</p>
                                </p>
                                <NavLink
                                  key={x.id}
                                  className="parcel-read-more"
                                  to={`/land-parcels/${x.slug}`}
                                >
                                  READ MORE &nbsp;
                                  <span className="right-triangle">▶</span>
                                </NavLink>
                              </div>
                            ))}
                          </>
                        </div>
                      </div>
                    </div>
                  )}
                  <div id="map-view"></div>
                </div>
              </div>
            </div>
          </div>
        </Landparcelscss>
      </>
    );
  }
}

export default Landparcels;
