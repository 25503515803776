import React, { Component } from "react";
import Homeslide from "../../components/Slider/slick";
import Logo from "../../assets/images/logo.png";
import Homeslide2 from "../../components/Slider/slick2";
import { pagesService } from "../../services/pages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagedetail: [],
    };
  }
  componentDidMount() {
    pagesService.pagesId("8").then(
      (res) => {
        if (res.status === "success") {
          this.setState({ pagedetail: res.pagedetail });
          sessionStorage.setItem("bodydata", res.pagedetail.body);
        } else {
          toast.error("Something went wrong", "Dashboard");
        }
      },
      (error) => {
        toast.error("Something went wrong", "Dashboard");
      }
    );
  }
  render() {
    return (
      <div>
        {this.state && !this.state.pagedetail.body ? (
          <p>Loding......</p>
        ) : (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{this.state.pagedetail.seotitle}</title>
              <link rel="canonical" href="/" />
            </Helmet>
            <Homeslide desc={this.state.pagedetail.body} />
            {/* <div className="l-content-container">
              <a
                href="#"
                id="home-tab-right"
                className="tab tabs-tab-right-white"
              >
                <span className="icon icons-arrow-next" />
              </a>
              <a
                href="#"
                id="home-tab-left"
                className="tab tabs-tab-left-white"
              >
                <span className="icon icons-arrow-prev" />
              </a>
              <div id="home">
                <div className="landscape parallax blurry" id="home-bg-blur">
                  &nbsp;
                </div>
                <div className="landscape parallax" id="home-bg">
                  &nbsp;
                </div>
                <div id="home-slides">
                  <div className="home-slide" id="home-slide-left">
                    <img
                      src={Logo}
                      alt="Land Preserve LLC"
                      className="header"
                    />
                    <div className="l-page-body l-page-body-headless">
                      <h1 className="title-alt">
                        Our Mission
                        <br />
                        Our Responsibility
                      </h1>
                      <img
                        src="img/our-story.png"
                        alt="Our Story"
                        id="about-our-story"
                        height={105}
                        width={313}
                      />
                      <p id="about-join-us">
                        <a href="members/register.html">Join Us</a>
                      </p>
                      <p id="about-scroll">
                        <a href="#home-page-copy" className="scroll-to">
                          Scroll
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="home-slide" id="home-slide-center">
                    <img
                      src={Logo}
                      alt="Land Preserve LLC"
                      className="header"
                    />
                    <div className="l-page-body l-page-body-headless">
                      <div className="content-blocks">
                        <div className="content-block alt">
                          <h2 style={{ textAlign: "center" }}>
                            Rethinking the World!!!!
                          </h2>
                          <h4 style={{ textAlign: "center" }}>
                            It's your land, your planet, your future!
                          </h4>
                          <p />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-slide" id="home-slide-right">
                    <img
                      src={Logo}
                      alt="Land Preserve LLC"
                      className="header"
                    />
                    <div className="l-page-body l-page-body-headless">
                      <div className="columns columns-2">
                        <div className="column form-column alpha">
                          <h2 className="column-header form-header">
                            Register{" "}
                            <span className="subtitle">New Member</span>
                          </h2>
                          <div className="column-body">
                            <form
                              action="https://lp.shadowcomm.com/members/register"
                              method="post"
                            >
                              <input
                                type="hidden"
                                name="security[token]"
                                defaultValue="$2a$10$4AjzPKphRZE4bvfY58gYD.SvnkXBTzbDmNlkfXyyw7NK7eFcpz90y"
                              />
                              <div className="field">
                                <label htmlFor="Email">Email *</label>
                                <input type="text" name="email" id="Email" />
                              </div>
                              <div className="field">
                                <label htmlFor="Password">Password *</label>
                                <input
                                  type="password"
                                  name="_password"
                                  id="Password"
                                />
                              </div>
                              <div className="field">
                                <label htmlFor="PasswordConfirm">
                                  Confirm Password *
                                </label>
                                <input
                                  type="password"
                                  name="_password_confirm"
                                  id="PasswordConfirm"
                                />
                              </div>
                              <div className="field">
                                <label htmlFor="Phone">Phone Number</label>
                                <input type="text" name="phone" id="Phone" />
                              </div>
                              <div className="field">
                                <label
                                  style={{ zIndex: 21 }}
                                  htmlFor="MemberReferringMemberCode"
                                >
                                  Referring Member Code &nbsp;
                                  <a
                                    className="modal"
                                    href="#member_code_modal"
                                  >
                                    What's this?
                                  </a>
                                </label>
                                <input
                                  type="text"
                                  name="_referring_member_code"
                                  id="MemberReferringMemberCode"
                                />
                              </div>
                              <div className="field">
                                <input
                                  type="hidden"
                                  name="agree"
                                  defaultValue
                                />
                                <input
                                  type="checkbox"
                                  name="agree"
                                  id="MemberAgree"
                                  defaultValue={1}
                                />
                                <label
                                  htmlFor="MemberAgree"
                                  style={{ display: "none" }}
                                >
                                  I agree to the
                                  <a href="files-2.json" target="_blank">
                                    Terms and Conditions
                                  </a>
                                </label>
                              </div>
                              <div className="field">
                                <input
                                  type="hidden"
                                  name="agree_land_use"
                                  defaultValue
                                />
                                <input
                                  type="checkbox"
                                  name="agree_land_use"
                                  id="MemberAgreeLandUse"
                                  defaultValue={1}
                                />
                                <label
                                  htmlFor="MemberAgreeLandUse"
                                  style={{ display: "none" }}
                                >
                                  I agree to the
                                  <a href="files-3.json" target="_blank">
                                    Land Use License Agreement
                                  </a>
                                </label>
                              </div>
                              <div className="field">
                                <input type="submit" defaultValue="Register" />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="column form-column omega">
                          <h2 className="column-header form-header">
                            Login{" "}
                            <span className="subtitle">Existing Member</span>
                          </h2>
                          <div className="column-body">
                            <form
                              action="https://lp.shadowcomm.com/member/login"
                              method="post"
                            >
                              <input
                                type="hidden"
                                name="security[token]"
                                defaultValue="$2a$10$jCQh3lhfDmnnpNR4LkQxouumpdavEzh4oiuJnvYSHzMXPWGsZyChW"
                              />
                              <div className="field">
                                <label htmlFor="Email">Email *</label>
                                <input type="text" name="email" id="Email" />
                              </div>
                              <div className="field">
                                <label htmlFor="Password">Password *</label>
                                <input
                                  type="password"
                                  name="password"
                                  id="Password"
                                />
                              </div>
                              <input type="submit" defaultValue="Log in" />
                              <a href="members/forgot.html">
                                Forgot your password?
                              </a>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="content-blocks">
                        <div className="content-block alt">
                          <p>* Required Field</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
              <div id="home-page-copy" className="hide">
                <div className="l-page-body">
                  <h1 className="l-page-title">
                    <span className="l-page-title-text"> The Facts </span>
                  </h1>
                  <div className="l-page-body-content">
                    <p>Do you think our planet is getting better or worse?</p>
                    <p>Do you do anything to make it better?</p>
                    <p>
                      People say they will do something, but it rarely happens.
                      Some people recycle or donate to charity, but it's not
                      making&nbsp;a difference. We all know something needs to
                      be done, but how do we go about it? Land Preserve is the
                      answer.
                    </p>
                    <p>
                      Land Preserve was created to provide a simple, easy,
                      inexpensive way for every person to help preserve land,
                      protect wildlife habitats and save the planet from our own
                      destructive behavior. If we all get together through this
                      website, we can make sure the earth starts getting better.
                    </p>
                    <p>
                      Millions and millions of acres could be preserved forever.
                      Why not do it? With your membership, we could start saving
                      these land parcels:
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Colorado - 46,790 acres (73 square miles) of amazing
                      terrain
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <img src="../www.landpreserve.com/uploads/land-parcels/JE%20Canyon%20Ranch.jpg" />
                      &nbsp;
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Hawaii -&nbsp;&nbsp;565 acres with incredible waterfalls,
                      pools and streams
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <img src="../www.landpreserve.com/uploads/land-parcels/Honolii%20Stream%20-%20Hawaii.jpg" />
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <strong />
                      &nbsp;British Columbia, Canada - 620 acres at the base of
                      Mount Tatlow
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <img src="../www.landpreserve.com/uploads/land-parcels/Elkin%20Creek%20-%20Canada.jpg" />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      This is just a small sample of the land parcels that could
                      be preserved forever by you, as a member&nbsp;of&nbsp;Land
                      Preserve.&nbsp;Let's preserve the land above, and make it
                      available for members to use. All this, for only $10.00
                      per month.&nbsp;This is an amazing endeavor.&nbsp;
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <strong>Our&nbsp;Story</strong>
                    </p>
                    <p>
                      We realized a simple thing - that by preserving land, we
                      wind up saving not only the land, but countless animals,
                      trees, plants,&nbsp;insects,&nbsp;and even fish. Plus,
                      preserving land and trees provides better air quality.
                      Preserving land was the key to everything, but land is
                      expensive. How do we acquire the land so that it can be
                      saved?
                    </p>
                    <p>
                      Then we came up with a plan. Create a 'club' that everyone
                      can join for a low monthly fee, like a gym membership. It
                      can't be free like many websites, because land costs a lot
                      of money.&nbsp;We discovered that most people thought $10
                      per month was cheap and affordable.&nbsp;That would be the
                      rate.
                    </p>
                    <p>
                      The next step was to build up to one million members,
                      which provides $10 million per month to buy land. We could
                      be buying land constantly, all over the country and world
                      and saving millions of acres and billions of plants and
                      animals!
                    </p>
                    <p>
                      We&nbsp;built the website, made it easy for people to sign
                      up, and created a referral system in order to spread the
                      word.
                    </p>
                    <p>
                      In&nbsp;order to get people to join and stay members, we
                      decided to let them actually see the land, stand on it,
                      and use it. That way, all members can see the results.
                      This was different than any other land preservation effort
                      ever undertaken.
                    </p>
                    <p>
                      Our&nbsp;job now is&nbsp;acquiring land. This will take
                      time. As membership grows, so will the portfolio of
                      land.&nbsp;
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <strong>Company Structure</strong>
                    </p>
                    <p>
                      Land Preserve is based in Colorado and&nbsp;different than
                      any conservation or preservation organization ever formed.
                      Land Preserve is&nbsp;<strong>not</strong> a charity or
                      non-profit organization, which&nbsp;are regulated by the
                      government. They are restricted in what they can do,
                      and&nbsp;often develop bad reputations through radical
                      efforts.&nbsp;Plus,&nbsp;a charity or non-profit is
                      usually&nbsp;formed&nbsp;just&nbsp;for the purpose
                      of&nbsp;avoiding income taxes. Why not just pay income
                      taxes and avoid all the government regulations?
                    </p>
                    <p>
                      At Land Preserve, the plan is to put all profits back into
                      the company to buy land. By doing so, we don't have to
                      worry about paying income taxes on profits anyway. If
                      there's a profit at the end of the day, then we&nbsp;will
                      take that and buy land. The purpose of Land Preserve is
                      not to make a profit, just to preserve more and more land.
                    </p>
                    <p>
                      Without government regulation, we can grow into a massive
                      worldwide force and preserve more land than any other
                      company or charity ever did.&nbsp;
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <strong>Being a Member</strong>
                    </p>
                    <p>
                      Please
                      <a href="https://www.landpreserve.com/members/register">
                        Join Now
                      </a>
                      , so we can start saving millions of acres of land.
                      Information on each preserved land parcel will be uploaded
                      to the website. You will be able to see where it is and go
                      check it out. This will be so amazing, you will be glad
                      you joined.
                    </p>
                    <p>
                      There are various benefits to becoming a member,
                      including:
                    </p>
                    <ul>
                      <li>
                        You will be preserving land&nbsp;forever,
                        <strong> </strong>throughout the U.S. and the world.
                      </li>
                      <li>
                        You will eventually be saving millions of acres of land
                        from ever being developed or destroyed.
                      </li>
                      <li>
                        You will be saving countless numbers of animals, plants,
                        trees&nbsp;and other living things.
                      </li>
                      <li>
                        You will&nbsp;be able to use parcels for hiking, biking,
                        walking your dog, horseback riding, etc.
                      </li>
                      <li>
                        You will be part of the generation that finally did
                        something about our dying world. You will be the one
                        that revived it.
                      </li>
                      <li>
                        You will have the opportunity to earn a referral fee if
                        you sign up a new member.
                      </li>
                      <li>
                        You will be able to access&nbsp;information on the land
                        being preserved, including maps, locations,
                        descriptions, size, terrain, etc.&nbsp;
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      Thank&nbsp;you for&nbsp;
                      <a href="https://www.landpreserve.com/members/register">
                        becoming a member
                      </a>
                      . Let's do this and make it work!
                    </p>
                    <p>Greg Owen, Founder of Land Preserve</p>
                  </div>
                </div>
                <div className="clear" />
              </div>
              <div id="member_code_modal" className="hide">
                <h2>Member Code</h2>
                <p>
                  Member Code is a unique identifier automatically assigned to
                  you when you become a member of Land Preserve (it is a random
                  set of numbers and letters). When you refer others, input
                  Member Code in the required field or ask them to input your
                  code if they are signing&nbsp;up themselves.&nbsp;This will
                  ensure that you receive credit for the referral.
                </p>
              </div>

            </div> */}
            <Homeslide2 />
            <ToastContainer />
          </>
        )}
      </div>
    );
  }
}
