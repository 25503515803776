import React, { Component } from "react";
import { Homeform } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import Story from "../../assets/images/our-story.png";

export class Mission extends Component {

  render() {

    const myStyle = {
      background: `white url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg") 50% 0 no-repeat fixed`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      marginTop: "48px",
      backgroundPosition: "30% 0px",

    };
    return (
      <div style={myStyle}>
        <div className="l-page-body l-page-body-headless">
          <Homeform>
            <img src={Logo} className="header" />
            <div className="l-page-body l-page-body-headless">
              <h1 className="title-alt" style={{ marginTop: "35px" }}>
                Our Mission
                <br />
                Our Responsibility{" "}
              </h1>
              <img
                src={Story}
                alt="Our Story"
                id="about-our-story"
                height="105"
                width="313"
              ></img>
              <p id="about-join-us">
                <a href="/members/register" style={{ color: "#fff", textDecoration: "none" }}>Join Us</a>
              </p>
              <p id="about-scroll">
                <a href="#home-page-copy" style={{ color: "#f7931e", textDecoration: "none" }} className="scroll-to">
                  Scroll
                </a>
              </p>
            </div>

            <div id="home-page-copy" class="hide" style={{ display: "block", color: "#6f6f6f" }}>
              <h1 className="l-page-title" style={{ color: "#6f6f6f", textAlign: "center" }}>
                <span className="l-page-title-text">The Facts </span>
              </h1>
              <div className="l-page-body">
                <div className="l-page-body-content" style={{ color: "#6f6f6f" }}>
                  <p dangerouslySetInnerHTML={{ __html: sessionStorage.getItem('bodydata') }}></p>
                </div>
              </div>
              <div className="clear" />
            </div>
          </Homeform>
        </div>
      </div>
    );
  }
}

export default Mission;
