import { faqs } from "../../data";
import {SmallBox} from "./AccordionItem.js";
import Accordion from 'react-bootstrap/Accordion'
const Accordions = () => {
  return (
    <Accordion>
      {faqs.map((faq, index) => (
        <SmallBox key={index}  title={faq.question}  dec={faq.answer}   no={index}/>
      ))}
    </Accordion>
  );
};

export default Accordions;