

import React, { Component } from "react";
import Slider1 from "react-slick";
import LandScape1 from '../../assets/images/1.jpeg'
import LandScape2 from '../../assets/images/2.jpeg'
import LandScape3 from '../../assets/images/3.jpeg'
import LandScape4 from '../../assets/images/4.jpeg'
import LandScape5 from '../../assets/images/5.jpeg'

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      autoplay 				: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "slidesfooter",
      arrows: false,
    };
    
    return (
      <div>
       
        <Slider1 {...settings}>
          <div >
            <img src={LandScape1} />
          </div>
          <div>
          <img src={LandScape2} />
          </div>
          <div>
          <img src={LandScape3} />
          </div>
          <div>
          <img src={LandScape4} />
          </div>
          <div>
          <img src={LandScape5} />
          </div>
          
        </Slider1>

        
      </div>
    );
  }
}