import React, { Component } from "react";
import { Logincss } from "./styles.js";
import Logo from "../../assets/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { userService } from "../../services/user.service";
import {
  useParams
} from "react-router-dom";
const Changepassword = () => {
  let { token } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const myStyle = {
    backgroundImage: `url("https://lp.shadowcomm.com/img/landscape-blurry-overlay.jpg")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    marginTop: "48px",
  }
  const onSubmit = (data) => {

    userService.resetPassword(token, data).then(
      (res) => {
        if (res.status == "success") {
          reset();
          toast.success(
            res.message,
            "Registration"
          );

        } else {
          toast.error(res.message, "Registration");
        }
      },
      (error) => {
        toast.error("Token is Invalid or Expired", "Registration");
      }
    );
  };
  return (
    <div style={myStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Forgot
        </title>
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="l-page-body l-page-body-headless">
        <Logincss>
          <img src={Logo} className="header" />
          <h2 className="column-header form-header mt-5">RESET YOUR PASSWORD</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                name="password"
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && (
                <div className="help-block">{errors.password.message}</div>
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password_confirmation"
                className="form-control"
                id="PasswordConfirm"
                placeholder="Password Confirm"
                {...register("password_confirmation", {
                  required: "Password Confirm is required",
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              {errors.password_confirmation && (
                <div className="help-block">
                  {errors.password_confirmation.message}
                </div>
              )}
            </div>

            <button type="submit" className="btn btn-primary" style={{ backgroundColor: "#f7931e", border: "none", float: "right" }}>Reset Password</button>
          </form>
        </Logincss>
        <ToastContainer />
      </div>
    </div>
  );

}

export default Changepassword;
